<template>
  <div class="page-header" v-bind:style="{
    ...props.backgroundImageURL && { background: `url(${props.backgroundImageURL}) no-repeat center center` }
  }">
    <div class="container">
      <h2 v-if="props.title" class="title mb-0">{{props.title}}</h2>
      <h5 v-if="props.subtitle" class="subtitle">{{props.subtitle}}</h5>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      props: {
        type: Object,
        default: function() {
          return {};
        }
      }
    }
  };
</script>


<style scoped>
  .page-header {
    background-attachment: fixed;
    background-size: cover!important;
    padding: 6rem 0 3rem;
    position: relative;
  }
  .page-header::after {
    background-color: var(--aff-primary);
    bottom: 0;
    content: "";
    left: 0;
    opacity: .85;
    position: absolute;
    right: 0;
    top: 0;
  }
  .page-header .title,
  .page-header .subtitle {
    color: #ffffff;
    position: relative;
    z-index: 1;
  }
</style>